const config = {
  ENVIRONMENT_NAME: 'dev',
  forwoodId: {
    URL: 'https://id.dev.gromit.forwoodsafety.com',
    APP_CLIENT_ID: '4jr302d04innf186ab0hrdb0cn',
    APIGATEWAY: 'https://oim80dqpm8.execute-api.us-west-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-west-2',
    URL: 'https://4bmib950q5.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'dev',
    VERSION: 'resources-container-1.3.0',
    HOSTNAME: 'https://resources.dev.gromit.forwoodsafety.com',
    TRAINING_MATERIALS_URL: 'https://iframe.dev.gromit.forwoodsafety.com/training-materials',
    OFFICIAL_CRM_ICONS_URL: 'https://iframe.dev.gromit.forwoodsafety.com/risk-icons',
    PRESENTATION_IFRAME_URL: 'https://iframe.dev.gromit.forwoodsafety.com/node/11990',
    IFRAME_ORIGIN_URL: 'https://iframe.dev.gromit.forwoodsafety.com'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
